<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '550px' }"
    header="DATOS DE CLIENTE"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-5">
          <div class="p-inputgroup flex align-items-center">
            <label for="nombre"><strong>TIPO CLIENTE: </strong> </label>
            <Dropdown
              v-model="tipo_cliente_selected"
              :options="tipos_clientes"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione tipo cliente..."
            >
            </Dropdown>
          </div>
        </div>
        <div class="field col-12 md:col-7">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">NOMBRES_APELLIDOS: </span>
            <span class="p-float-label">
              <Textarea
                v-model="data_cliente_factura.nombre"
                v-tooltip.top="'Difunto'"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">C.I.:</span>
            <span class="p-float-label">
              <Textarea
                v-model="data_cliente_factura.ci_nit"
                v-tooltip.top="'Difunto'"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-8">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">DIRECCI&Oacute;N:</span>
            <span class="p-float-label">
              <Textarea
                v-model="data_cliente_factura.direccion"
                v-tooltip.top="'Difunto'"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">CELULAR:</span>
            <span class="p-float-label">
              <Textarea
                v-model="data_cliente_factura.telefono"
                v-tooltip.top="'Difunto'"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-8">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">CORREO:</span>
            <span class="p-float-label">
              <Textarea
                v-model="data_cliente_factura.correo"
                v-tooltip.top="'Difunto'"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <!-- <div class="field col-12 md:col-12">
          <label for="nombre"
            ><strong>RAZ&Oacute;N SOCIAL: </strong>
            <span class="p-invalid">*</span></label
          >
          <InputText
            id="nombre"
            v-model.trim="data_cliente_factura.nombre"
            required="true"
            autofocus
          />
          <small class="p-invalid" v-if="errors.nombre">{{
            errors.nombre[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-6">
          <label for="ci_nit"><strong>TIPO DOCUMENTO: </strong></label>
          <Dropdown
            id="tipo_documento_identidad"
            v-model="tipo_documento_selected"
            :options="tipos_documentos"
            optionLabel="label"
            placeholder="Seleccione tipo documento..."
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-4">
          <label for="ci_nit"><strong>CI/NIT: </strong></label>
          <InputText id="ci_nit" v-model="data_cliente_factura.ci_nit" />
          <small class="p-invalid" v-if="errors.ci_nit">{{
            errors.ci_nit[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <label for="ci_nit"><strong>EXT. </strong></label>
          <InputText
            id="ci_nit"
            v-model="data_cliente_factura.complemento_documento"
          />
        </div>
        <div class="field col-12 md:col-8">
          <label for="correo"
            ><strong>CORREO ELECTR&Oacute;NICO: </strong>
          </label>
          <InputText
            id="correo"
            v-model="data_cliente_factura.correo"
            required="true"
          />
          <small class="p-invalid" v-if="errors.correo">{{
            errors.correo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="estado"
            ><strong>ESTADO: </strong><span class="p-invalid">*</span></label
          >
          <Dropdown
            id="estado"
            v-model="data_cliente_factura.estado"
            :disabled="!data_cliente_factura.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div> -->
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        :label="data_cliente_factura.id ? 'ACTUALIZAR' : 'GUARDAR'"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="guardarClienteFactura"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import ClienteService from "@/service/ClienteService";
import TipoDocumentoIdentidadService from "@/service/TipoDocumentoIdentidadService";
export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cliente_factura: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  clienteService: null,
  tipoDocumentoIdentidadService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_cliente_factura: this.cliente_factura,
      tipo_documento_selected: {},
      enviado: false,
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      tipos_documentos: [],
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
        { label: "OBITADO", value: 2 },
      ],
      tipo_cliente_selected: 1,
      tipos_clientes: [
        { label: "PARTICULAR", value: 1 },
        { label: "FUNERARIA", value: 2 },
      ],
    };
  },
  created() {
    this.clienteService = new ClienteService();
    this.tipoDocumentoIdentidadService = new TipoDocumentoIdentidadService();

    this.cargarTiposDocumentos();
  },
  methods: {
    cargarTiposDocumentos() {
      this.tipoDocumentoIdentidadService
        .getAllTipoDocumentoIdentidad()
        .then((data) => {
          data.forEach((element) => {
            if (
              this.data_cliente_factura.id &&
              this.data_cliente_factura.tipo_documento == element.id
            ) {
              this.tipo_documento_selected = {
                label: element.codigo + " - " + element.descripcion,
                value: element.id,
              };
            } else {
              if (element.id == 1) {
                this.tipo_documento_selected = {
                  label: element.codigo + " - " + element.descripcion,
                  value: element.id,
                };
              }
            }
            this.tipos_documentos.push({
              label: element.codigo + " - " + element.descripcion,
              value: element.id,
            });
          });
        });
    },
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.enviado = false;
    },
    guardarClienteFactura() {
      this.errors = {};
      this.enviado = true;
      //editar cliente_factura
      if (this.cliente_factura.id) {
        let cliente_enviar = {
          ...this.data_cliente_factura,
          tipo_documento: this.tipo_documento_selected.value,
          estado: this.data_cliente_factura.estado.value,
        };
        this.clienteService
          .updatedClienteFactura(cliente_enviar)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 3000,
              });
              this.$emit("actualizarListado");
              this.ocultarDialog();
            }
            this.enviado = false;
          });
      } else {
        //CREANDO UN NUEVO PROVEEDOR
        let tmp = this.data_cliente_factura;
        this.data_cliente_factura = {
          ...this.data_cliente_factura,
          tipo_documento: this.tipo_documento_selected.value,
          estado: this.data_cliente_factura.estado.value,
        };
        this.clienteService
          .sendClienteFacturaNuevo(this.data_cliente_factura)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
              this.data_cliente_factura = tmp;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 10000,
              });

              this.$emit("actualizarListado", data.cliente_factura);
              this.ocultarDialog();
            }
            this.enviado = false;
          });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    cliente_factura(val) {
      this.data_cliente_factura = val;
      if (this.data_cliente_factura.id) {
        this.tipo_documento_selected = this.tipos_documentos.find(
          (item) => item.value == val.tipo_documento
        );
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-float-label {
  .p-inputtextarea {
    color: #0062ff;
    text-transform: capitalize;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px dotted #00346b;
    &:focus {
      border-bottom: px solid #00346b;
    }
  }
}
.p-float-label label {
  color: #003a6e;
}
.p-divider {
  font-weight: bold;
  color: #003a6e;
}
.p-radiobutton-label {
  font-weight: bold;
  color: #003a6e;
}
.color-linea {
  border: 2px solid #003a6e;
  border-radius: 5px;
}
.titulo-izquierdo {
  font-weight: bold;
  color: #003a6e;
}
.texto-span {
  font-weight: bold;
  color: #003a6e;
}
</style>
