<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Servicios de Cremación</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Clientes</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <Toast />
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Clientes
          <Badge size="xlarge" severity="success"> {{ totalRecords }}</Badge>
        </div>
      </div>
      <div class="flex mt-3 lg:mt-0">
        <Button
          label="Nuevo"
          v-if="'Cliente Crear' in auth.user.permissions"
          v-tooltip.top="'Nuevo Cliente Factura'"
          icon="pi pi-plus"
          class="p-button-outlined mr-2 p-button-lg"
          @click="openNuevo"
        />
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dtclientes_facturas"
        :value="clientes_facturas"
        :loading="cargando"
        dataKey="id"
        :paginator="true"
        :rows="20"
        :rowHover="true"
        :filters="buscar"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Clientes"
        responsiveLayout="scroll"
        filterDisplay="menu"
        :lazy="true"
        :totalRecords="totalRecords"
        v-model:filters="buscar"
        :rowsPerPageOptions="[20, 500, 1000]"
        @page="onPage($event)"
        @sort="onSort($event)"
        @filter="onFilter($event)"
        stripedRows
        showGridlines
        selectionMode="single"
        @row-dblclick="editClienteFactura($event.data)"
      >
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>

        <Column
          field="id"
          header="CÓDIGO"
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="Código"
            />
          </template>
        </Column>
        <Column field="tipo_cliente" header="TIPO CLIENTE">
          <template #body="{ data }">
            <span>{{ data.tipo_cliente ?? "PARTICULAR" }}</span>
          </template>
        </Column>
        <Column
          field="nombre"
          header="NOMBRES Y APELLIDOS"
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="Nombre Completo..."
            />
          </template>
        </Column>
        <Column
          field="ci_nit"
          header="CI"
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="CI/NIT"
            />
          </template>
        </Column>
        <Column field="telefono" header="CELULAR"></Column>
        <Column field="correo" header="CORREO ELECTRÓNICO">
          {{ data.correo }}
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado.value]">
              {{ data.estado.label }}
            </span>
          </template>
        </Column>
        <Column headerStyle="min-width:10rem;" header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised"
              v-tooltip.top="'Ver Acciones'"
              icon="pi pi-ellipsis-v"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="deleteClienteDialog"
      :style="{ width: '450px' }"
      header="Confirmar"
      :modal="true"
    >
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="cliente_factura"
          >Estas Seguro que quieres eliminar al cliente_factura factura
          <b>{{ cliente_factura.nombre }}</b> ?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-secondary"
          @click="deleteClienteDialog = false"
        />
        <Button
          label="Si"
          icon="pi pi-check"
          class="p-button-primary"
          @click="deleteCliente"
        />
      </template>
    </Dialog>
    <ClienteFactura
      :show="clienteDialog"
      :cliente_factura="cliente_factura"
      @closeModal="ocultarDialog"
      @actualizarListado="cargarClientesFacturas"
    >
    </ClienteFactura>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ClienteService from "@/service/ClienteService";
import { useAuth } from "@/stores";
import ClienteFactura from "@/module/clientes_facturas/ClienteFactura.vue";

export default {
  components: {
    ClienteFactura,
  },
  data() {
    return {
      clientes_facturas: [],
      cargando: true,
      clienteDialog: false,
      deleteClienteDialog: false,
      cliente_factura: {},
      buscar: {},
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
        { label: "OBITADO", value: 2 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock", "status-outofstock"],
      lazyParams: {},
      totalRecords: 0,
    };
  },
  clienteService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.clienteService = new ClienteService();
    this.initBuscar();
  },
  mounted() {
    this.lazyParams = {
      first: 0,
      rows: this.$refs.dtclientes_facturas.rows,
      sortField: null,
      sortOrder: null,
      filters: this.buscar,
    };
    this.cargarClientesFacturas();
  },
  methods: {
    onPage(event) {
      this.lazyParams = event;
      this.cargarClientesFacturas();
    },
    onSort(event) {
      this.lazyParams = event;
    },
    onFilter() {
      this.lazyParams.filters = this.buscar;
      this.cargarClientesFacturas();
    },
    acciones(datos) {
      let permisosUsuarioAuth = this.auth.user.permissions;
      return [
        {
          label: "Editar",
          disabled: "Cliente Editar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-pencil",
          command: () => {
            this.editClienteFactura(datos);
          },
        },
        {
          label: "Eliminar",
          disabled: "Cliente Eliminar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-trash",
          command: () => {
            this.confirmDeleteClienteFactura(datos);
          },
        },
      ];
    },
    cargarClientesFacturas() {
      this.cargando = true;
      setTimeout(() => {
        this.clienteService
          .getClientesFacturas({ dt_params: JSON.stringify(this.lazyParams) })
          .then((data) => {
            this.totalRecords = data.clientes_facturas.total;

            this.clientes_facturas = data.clientes_facturas.data || [];
            this.cargando = false;

            this.clientes_facturas.forEach((cliente_factura) => {
              cliente_factura.created_at = new Date(cliente_factura.created_at);
              cliente_factura.updated_at = new Date(cliente_factura.updated_at);
              cliente_factura.estado = {
                label: this.textoEstado(cliente_factura.estado),
                value: cliente_factura.estado,
              };
            });
          });
      }, Math.random() * 1000 + 250);
    },
    openNuevo() {
      this.cliente_factura = {
        estado: { label: "Activo", value: 1 },
      };
      this.enviado = false;
      this.clienteDialog = true;
    },
    ocultarDialog() {
      this.clienteDialog = false;
      this.enviado = false;
    },
    ocultalModalCliente() {
      this.clienteDialog = false;
      this.cliente_factura = {};
    },
    textoEstado(estado) {
      switch (estado) {
        case 0:
          return "INACTIVO";
        case 1:
          return "ACTIVO";
        case 2:
          return "OBITADO";
        default:
          return "SIN ESTADO";
      }
    },
    editClienteFactura(cliente_factura) {
      this.cliente_factura = {
        ...cliente_factura,
        estado: {
          label: this.textoEstado(cliente_factura.estado.value),
          value: cliente_factura.estado.value,
        },
      };

      this.clienteDialog = true;
    },
    confirmDeleteClienteFactura(cliente_factura) {
      this.cliente_factura = { ...cliente_factura };
      this.deleteClienteDialog = true;
    },
    deleteCliente() {
      this.clienteService
        .deleteClienteFactura(this.cliente_factura)
        .then((data) => {
          if (data.status == 200) {
            this.$toast.add({
              severity: "success",
              summary: "Éxito",
              detail: data.mensaje,
              life: 3000,
            });
            this.cargarClientesFacturas();
            this.deleteClienteDialog = false;
            this.cliente_factura = {};
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: data.mensaje,
              life: 3000,
            });
            this.deleteClienteDialog = false;
          }
        });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.clientes_facturas.length; i++) {
        if (this.clientes_facturas[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    initBuscar() {
      this.buscar = {
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        nombre: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ci_nit: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    formatDate(value) {
      return value.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
